import request from '@/common/utils/request'
import Vue from "vue";

export function getDeviceUserList(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/device-user/get-device-user-list',
    method: 'post',
    data
  })
}

export function downOne(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/device-user/down-one',
    method: 'post',
    data
  })
}

export function downAll(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/device-user/down-all',
    method: 'post',
    data
  })
}

export function refresh(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/device-user/refresh',
    method: 'post',
    data
  })
}

export function refreshOne(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/device-user/refresh-one',
    method: 'post',
    data
  })
}

export function getDeviceUserLogList(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/device-user/get-device-user-log-list',
    method: 'post',
    data
  })
}

export function deleteAll(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/device-user/delete-all',
    method: 'post',
    data
  })
}


export function deleteOne(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/device-user/delete-one',
    method: 'post',
    data
  })
}
