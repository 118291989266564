import request from '@/common/utils/request'
import Vue from "vue";

export function getPlateList() {
  return request({
    url: Vue.prototype.$baseUrl + '/comapi/plate/get-plate-list',
    method: 'post'
  })
}

// 获取会议管理页面列表
export function getPlateList2(data){
  return request({
    url: Vue.prototype.$adminApiUrl + '/plate/get-plate-list2',
    method: 'post',
    data
  })
}

export function addPlate(data){
  return request({
    url: Vue.prototype.$adminApiUrl + '/plate/add-plate',
    method: 'post',
    data
  })
}

export function updatePlate(data){
  return request({
    url: Vue.prototype.$adminApiUrl + '/plate/update-plate',
    method: 'post',
    data
  })
}

export function getPlateListWithGroup(params) {
  return request({
    url: Vue.prototype.$baseUrl + '/comapi/plate/get-plate-list-with-group2',
    method: 'post',
    params: params
  })
}
